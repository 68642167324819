import { ExtendedTableT, ExtendedVenueSettingsT, ModifiedExtendedReservationT, TableT } from '@/types/globalTypes'




export const getDefaultExtendedReservationStructure = (): ModifiedExtendedReservationT => {
    return {
        id: '',
        email: '',
        extra_information: '',
        name_of_the_person: '',
        date_of_reservation: '',
        people: 0,
        phone_number: '',
        status: '',
        is_temporary: false,
        is_walk_in: false,
        table: getDefaultTableStructure(),
        actions: '',
        is_static: false,
        agreed_to_terms: false,
        created_at: '',
        end_time: '',
        guest_id: '',
        start_time: '',
        waiter_responded: false,
        hasPendingOrder: false,
        pin_code: '',
        skip_auto_pin_renewal: false,
        table_id: '',
        venue_id: ''
    }
}

// Type guard to check if table is of type TableT
export const isTable = (table: TableT | null | string): table is TableT => {
    // Check if 'table' is not null and not a string
    return table !== null && typeof table !== 'string'
}

export const getDefaultTableStructure = (): ExtendedTableT => {
    return {
        floor: '',
        id: '',
        reservation: {
            id: '',
            email: '',
            extra_information: '',
            name_of_the_person: '',
            date_of_reservation: '',
            people: 0,
            phone_number: '',
            status: '',
            is_temporary: false,
            is_walk_in: false,
            table: null,
            actions: '',
            is_static: true,
            agreed_to_terms: false,
            created_at: '',
            end_time: '',
            guest_id: '',
            start_time: '',
            waiter_responded: false,
            hasPendingOrder: false,
            pin_code: '',
            skip_auto_pin_renewal: false,
            table_id: '',
            venue_id: ''
        },
        reserved: false,
        section: '',
        table_name: '',
        created_at: '',
        is_static: false,
        section_id: '',
        static_reservation_id: '',
        table_date: '',
        waiter: '',
        waiter_name: '',
        venue_id: ''
    }
}


export const getDefaultVenueSettings = (): ExtendedVenueSettingsT => {
    return {
        address: '',
        allow_reservations: false,
        banner_url: null,
        capacity: null,
        currency: null,
        description: null,
        duration: null,
        email: null,
        facebook_link: null,
        id: '',
        initial_setup: null,
        instagram_link: null,
        languages: null,
        latitude: null,
        linkedin_link: null,
        logo_url: null,
        longitude: null,
        map_url: null,
        max_calendar_days: 30,
        name: '',
        online_payments: null,
        payment_methods: null,
        phone_number: null,
        reservation_lead_time: '00:00',
        skip_auto_pin_renewal: false,
        tiktok_link: null,
        venue_timezone: '',
        venue_type: null,
        website: null,
        youtube_link: null,
        working_hours: {
            0: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 0, venue_id: '' },
            1: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 1, venue_id: '' },
            2: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 2, venue_id: '' },
            3: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 3, venue_id: '' },
            4: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 4, venue_id: '' },
            5: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 5, venue_id: '' },
            6: { starting_hours: '00:00', ending_hours: '00:00', is_active: false, day_of_week: 6, venue_id: '' },
        },
        active_working_hours: {
            starting_hours: '00:00',
            ending_hours: '00:00',
            is_active: false,
            day_of_week: 0,
            venue_id: ''
        },
        starting_hours: '00:00',
        ending_hours: '00:00',
        days_of_operation: [],
    }
};
