import { useMemo } from 'react'

import { captureException } from '@sentry/nextjs'

import { useAuthContext } from '@/context/AuthContext'

import { ExtendedVenueSettingsT, MappedWorkingHoursT, VenueInformationResponse, VenueSettingsT } from '@/types/globalTypes'


const useVenueServiceApi = () => {
    const { supabaseClient } = useAuthContext()

    const getVenueInformation = async (): Promise<VenueInformationResponse> => {
        let { data: venue_settings, error } = await supabaseClient
            .from('venue_settings')
            .select('*')
            .single()

        if (error) {
            captureException(error)
            return [null, error]
        }

        const { data: active_working_hours_data, error: active_working_hours_error } =
            await supabaseClient.rpc('get_current_working_hours', {
                p_venue_id: venue_settings.id
            })

        let { data: working_hours_data, error: working_hours_error } = await supabaseClient
            .from('working_hours')
            .select('*')

        if (working_hours_error) {
            captureException(working_hours_error)
            return [null, working_hours_error]
        }

        // Optionally, you might want to structure the working hours data in a way that's easy to work with.
        // For example, converting it to an object keyed by day_of_week:
        const working_hours: MappedWorkingHoursT = working_hours_data.reduce(
            (acc, { day_of_week, starting_hours, ending_hours, is_active }) => {
                acc[day_of_week] = { starting_hours, ending_hours, is_active, day_of_week }
                return acc
            },
            {}
        )

        const days_of_operation = working_hours_data
            .filter(({ is_active }) => is_active)
            .map(({ day_of_week }) => day_of_week)

        if (active_working_hours_error) {
            captureException(active_working_hours_error)
            return [null, active_working_hours_error]
        }

        /*  As there should only be one record for the current day, just take the first record if no record is found then it means that days has no working hours we just send is_active = false */
        const currentWorkingHours = active_working_hours_data[0] || {
            starting_hours: '00:00:00',
            ending_hours: '00:00:00',
            is_active: false,
            day_of_week: 1,
            venue_id: venue_settings.id
        }

        const extendedVenueSettings: ExtendedVenueSettingsT = {
            ...venue_settings,
            reservation_lead_time: venue_settings.reservation_lead_time as string,
            working_hours,
            active_working_hours: currentWorkingHours,
            starting_hours: currentWorkingHours.starting_hours,
            ending_hours: currentWorkingHours.ending_hours,
            days_of_operation: days_of_operation,
        }

        return [extendedVenueSettings, null]
    }

    const updateVenueInformation = async (venueData: VenueSettingsT) => {
        let { data: venue_settings, error } = await supabaseClient
            .from('venue_settings')
            .update({ ...venueData })
            .eq('id', venueData.id)

        if (error) {
            captureException(error)
        }
        return [venue_settings, error]
    }

    const getVenueEvents = async () => {
        let { data, error } = await supabaseClient.rpc('get_active_events')
        if (error) {
            console.error('Error fetching events:', error)
            captureException(error)
            return [null, error]
        }

        return [data, error]
    }

    const objectApi = useMemo(
        () => ({
            getVenueInformation,
            updateVenueInformation,
            getVenueEvents,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [supabaseClient]
    )

    return objectApi
}

export default useVenueServiceApi
