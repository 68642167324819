import {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'

import { useRouter } from 'next/router'

import { ApplicationContext } from '@/context/ApplicationContext'

import LoadingSpinner from '@/components/shared/LoadingSpinner/LoadingSpinner.tsx'

import { ApplicationState, Prettify, TLanguageOption } from '@/types/globalTypes.js'

/* Language options array, containing all available languages for the application, which will be available in the dropdown */
export const allLanguageOptions = [
    { id: 'en', value: 'en', label: 'English', countryCode: 'GB', primary: true, disabled: true },
    {
        id: 'bg',
        value: 'bg',
        label: 'Български',
        countryCode: 'BG',
        primary: false,
        disabled: false,
    },
    { id: 'de', value: 'de', label: 'Deutsch', countryCode: 'DE', primary: false, disabled: false },
    {
        id: 'fr',
        value: 'fr',
        label: 'Français',
        countryCode: 'FR',
        primary: false,
        disabled: false,
    },
    { id: 'ru', value: 'ru', label: 'Русский', countryCode: 'RU', primary: false, disabled: false },
    { id: 'ro', value: 'ro', label: 'Română', countryCode: 'RO', primary: false, disabled: false },
    {
        id: 'it',
        value: 'it',
        label: 'Italiano',
        countryCode: 'IT',
        primary: false,
        disabled: false,
    },
]

// This import statement is used for type inference.
type TranslationModule = Prettify<typeof import('./en.ts')>

const TranslationContext = createContext<
    | {
          translations: TranslationModule
          languageLocale: string
          setLanguageLocale: Dispatch<SetStateAction<string>>
      }
    | undefined
>(undefined)

const TranslationProvider: React.FC<{
    children: React.ReactNode
}> = ({ children }) => {
    const router = useRouter()
    const [loadedTranslations, setLoadedTranslations] = useState<{
        [locale: string]: TranslationModule
    }>({})
    const [languageLocale, setLanguageLocale] = useState(() => {
        // Initial locale set based on the router's locale or a default
        return (
            allLanguageOptions.find((option) => option.value === router.locale)?.value ||
            allLanguageOptions[0].value
        )
    })
    /* We use the second state for the locale to only update it after the module is ready this way we prevent any context errors */
    const [activeLocale, setActiveLocale] = useState(languageLocale)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let isCurrent = true

        // Dynamically load the translation module for the new locale
        import(`./${languageLocale}.ts`)
            .then((translationsModule: TranslationModule) => {
                if (isCurrent) {
                    setLoadedTranslations((prev) => ({
                        ...prev,
                        [languageLocale]: translationsModule,
                    }))
                    setActiveLocale(languageLocale) // Update the active locale once the module is loaded
                    setLoading(false)
                }
            })
            .catch((error) => {
                if (isCurrent) {
                    console.error(
                        `Failed to load translations for language ${languageLocale}:`,
                        error
                    )
                    setLoading(false)
                }
            })

        return () => {
            isCurrent = false
        }
    }, [languageLocale])

    const providerValue = useMemo(
        () => ({
            translations: loadedTranslations[activeLocale],
            languageLocale: activeLocale,
            setLanguageLocale,
            loading,
        }),
        [loadedTranslations, activeLocale, loading]
    )

    if (loading) {
        return (
            <LoadingSpinner
                className="flex w-screen h-screen m-auto"
                svgClassName="m-auto h-8 w-8"
            />
        )
    }

    return (
        <TranslationContext.Provider value={providerValue}>{children}</TranslationContext.Provider>
    )
}
/* The hook retuns all translated texts by code. All available keys/texts in case of not passed value for key parameter
 * @param {*} languageCode
 * @param {*} key
 */
export const useGetTranslations = () => {
    const { translations } = useContext(TranslationContext)
    if (!translations) {
        throw new Error('Translations must be used within a TranslationProvider')
    }
    return translations
}
export const useTranslationContext = () => {
    const context = useContext(TranslationContext)
    if (!context) {
        throw new Error('Translation Context must be used within a TranslationProvider')
    }
    return context
}

export const useLanguageOptions = (): TLanguageOption[] => {
    const applicationState: ApplicationState | undefined =
        useContext(ApplicationContext)?.applicationState

    if (!applicationState) {
        throw new Error('applicationState must be used within a TranslationProvider')
    }

    const selectedLanguages = applicationState?.venue?.languages

    if (selectedLanguages && selectedLanguages.length > 0) {
        return allLanguageOptions.filter((option) => selectedLanguages.includes(option.value))
    } else {
        return allLanguageOptions // Default to all available language options
    }
}

export default TranslationProvider
